import React from 'react';
import CryptoJS from 'crypto-js';

export function encryptFile(username, password, randomIv) {
  const secretKey = 'K@l0lyT1c@2o#$75'; // Replace with your secret key
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const options = {
    mode: CryptoJS.mode.CBC, // Mode
    padding: CryptoJS.pad.Pkcs7, // Padding
    iv: CryptoJS.enc.Utf8.parse(`secret_iv_test${randomIv}`) // Initialization Vector (IV)
  };

  const encryptedUsername = CryptoJS.AES.encrypt(username, key, options).toString();
  const encryptedPassword = CryptoJS.AES.encrypt(password, key, options).toString();

  return {
    username: encryptedUsername,
    passwordc: encryptedPassword
  };
}

export function encryptValue(data, randomIv) {
  const datatobe = typeof data === 'number' ? data.toString() : data;
  console.log(randomIv, 'line no 22');
  const secretKey = 'K@l0lyT1c@2o#$75'; // Replace with your secret key
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const options = {
    mode: CryptoJS.mode.CBC, // Mode
    padding: CryptoJS.pad.Pkcs7, // Padding
    iv: CryptoJS.enc.Utf8.parse(`secret_iv_tt${randomIv}`) // Initialization Vector (IV)
  };

  const encryptedData = CryptoJS.AES.encrypt(datatobe, key, options).toString();
  console.log('encryptedData', encryptedData);
  const replacedData = encryptedData.replace(/\//g, '@');
  // const encryptedPassword = CryptoJS.AES.encrypt(password, key, options).toString();

  return replacedData;
}

function decrypt(ciphertext, key, iv) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, key, { iv });
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export function encryptFormData(formData, randomIv) {
  const secretKey = 'K@l0lyT1c@2o#$75';
  const nonBinaryData = {};

  // Create a new FormData object for binary data
  const binaryFormData = new FormData();

  // Iterate over form data entries
  for (const [key, value] of formData.entries()) {
    // Check if the value is binary data
    if (value instanceof File || value instanceof Blob) {
      // If binary data, append it to binaryFormData
      binaryFormData.append(key, value);
    } else {
      // Encrypt non-binary data
      // const encryptedValue = CryptoJS.AES.encrypt(value.toString(), secretKey).toString();
      // Store encrypted value in encryptedNonBinaryData object
      nonBinaryData[key] = value;
    }
  }
  const payload = encryptPayload(nonBinaryData, randomIv);
  // binaryFormData.append('payload', payload);

  return payload;
}

export function encryptFileVerifyDoc(username, randomIv) {
  console.log(randomIv, 'line no 22');
  const secretKey = 'K@l0lyT1c@2o#$75'; // Replace with your secret key
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const options = {
    mode: CryptoJS.mode.CBC, // Mode
    padding: CryptoJS.pad.Pkcs7, // Padding
    iv: CryptoJS.enc.Utf8.parse(`secret_iv_tt${randomIv}`) // Initialization Vector (IV)
  };

  const encryptedUsername = CryptoJS.AES.encrypt(username, key, options).toString();
  // const encryptedPassword = CryptoJS.AES.encrypt(password, key, options).toString();

  return encryptedUsername;
}

export function encryptPayload(obj, randomIv) {
  console.log('Arrayresult', Array.isArray(obj), obj);
  const secretKey = 'K@l0lyT1c@2o#$75'; // Keeping the same secret key

  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const options = {
    iv: CryptoJS.enc.Utf8.parse(`secret_iv_tt${randomIv}`),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  };

  // If obj is a string (file content), handle it directly
  let jsonString;
  if (typeof obj === 'string') {
    jsonString = obj;
  } else {
    // Sort keys alphabetically before converting to JSON string
    const sortedObj = {};
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        sortedObj[key] = obj[key];
      });
    jsonString = JSON.stringify(sortedObj);
  }

  // Encrypt the JSON string or file content using the key and options
  const encryptedData = CryptoJS.AES.encrypt(jsonString, key, options).toString();
  const replacedData = encryptedData.replace(/\//g, '@');
  return replacedData;
}

export function encryptArrayOfObjects(data, randomIv) {
  console.log('encryptArrayOfObjectsdata', data);
  const secretKey = 'K@l0lyT1c@2o#$75';
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: CryptoJS.enc.Utf8.parse(`secret_iv_tt${randomIv}`),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  ).toString();
  console.log('ciphertext', cipherText);
  const replacedData = cipherText.replace(/\//g, '@');
  return replacedData;
}

export function decryptFile(encryptedData, randomIv) {
  const secretKey = 'K@l0lyT1c@2o#$75'; // Replace with your secret key
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const options = {
    mode: CryptoJS.mode.CBC, // Mode
    padding: CryptoJS.pad.Pkcs7, // Padding
    iv: CryptoJS.enc.Utf8.parse(`secret_iv_test${randomIv}`) // Initialization Vector (IV)
  };

  // Decrypt the encrypted data
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key, options);

  // Convert decrypted bytes to plaintext
  const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);

  // Parse decrypted JSON string to object
  const decryptedObj = JSON.parse(decryptedString);
  console.log('decryptedObj', decryptedObj);

  return decryptedObj;
}

export function encode(key, clear) {
  const enc = [];
  for (let i = 0; i < clear.length; i++) {
    const keyC = key[i % key.length];
    const encC = String.fromCharCode((clear.charCodeAt(i) + keyC.charCodeAt(0)) % 256);
    enc.push(encC);
  }
  return btoa(enc.join('')).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
